

























































































































import { Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Chart from 'chart.js/auto';
import { Application } from '@/models';
import DashboardResponse from '@/api/response/dashboard';
import { MerchantAPI } from '@/api';
import User from '../models/user';
import { ToastProgrammatic as Toast } from 'buefy';

@Component
export default class Dashboard extends Vue {
  private days = 7;
  private isLoading: boolean = false;
  private applications: Application[] = [];
  private application = 'all';
  private docsUrl = location.host.replace('merchant', 'docs');

  private transactionChart: Chart | undefined;
  private errorChart: Chart | undefined;

  public mounted() {
    MerchantAPI
        .applications({})
        .then((res) => this.applications = res.payload)
        .catch(err => {
          if (err.response?.status !== 401 && err.response?.status !== 403) {
            alert(err);
          }
        });

    this.transactionChart = this.initTransactionChart();
    this.errorChart = this.initErrorChart();

    this.loadChartData();
  }

  public user(): User | null {
    return this.$store.getters['auth/user'];
  }

  public userEmailVerified(): boolean {
    const user = this.user();

    return user !== null && user.hasVerifiedEmail();
  }

  @Watch('application', { immediate: false, deep: true })
  onApplicationChanged() {
    this.loadChartData();
  }

  private loadChartData() {
    this.isLoading = true;

    MerchantAPI
        .dashboard({
          days: this.days,
          app_key: this.application,
        })
        .then((res: DashboardResponse) => {
          if (res.transactions.labels.length > 0 && res.transactions.datasets.length > 0) {
            this.transactionChart?.data.labels?.splice(0, this.transactionChart?.data.labels?.length);
            this.transactionChart?.data.labels?.push(...res.transactions.labels);
            this.transactionChart?.data.datasets?.splice(0, this.transactionChart?.data.labels?.length);
            this.transactionChart?.data.datasets?.push(...res.transactions.datasets);
            this.transactionChart?.update();
          }

          if (res.errors.labels.length > 0 && res.errors.datasets.length > 0) {
            this.errorChart?.data.labels?.splice(0, this.errorChart?.data.labels?.length);
            this.errorChart?.data.labels?.push(...res.errors.labels);
            this.errorChart?.data.datasets?.splice(0, this.errorChart?.data.labels?.length);
            this.errorChart?.data.datasets?.push(...res.errors.datasets);
            this.errorChart?.update();
          }
        })
        .catch((err: any) => {
          throw err;
        }).finally(() => {
      this.isLoading = false;
    });
  }

  private initTransactionChart() { // static
    return new Chart(document.getElementById(`js__chart-transactions`) as HTMLCanvasElement, {
      type: 'bar',
      data: this.prepareTransactionChart(),
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  public verificationAlert() {
    Toast.open({
      message: this.$t('NeedToVerifyEmail').toString(),
      position: 'is-bottom',
      type: 'is-danger',
    });
  }

  private prepareTransactionChart() { // static
    return {
      labels: [],
      datasets: [
        {
          label: this.$t('Success').toString(),
          data: [],
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)'
        },
        {
          label: this.$t('Cancel').toString(),
          data: [],
          borderColor: 'rgb(201, 203, 207)',
          backgroundColor: 'rgba(201, 203, 207, 0.5)'
        },
        {
          label: this.$t('Fail').toString(),
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ]
    };
  }

  private initErrorChart() { // static
    return new Chart(document.getElementById(`js__chart-errors`) as HTMLCanvasElement, {
      type: 'line',
      data: this.prepareErrorChart(),
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  private prepareErrorChart() { // static
    return {
      labels: [],
      datasets: [
        {
          label: this.$t('Errors').toString(),
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ]
    };
  }
}
